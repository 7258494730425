import React, { lazy, Suspense } from "react";
import { LayoutWithLoading } from "../components/layout";

const AppWithCaseManagement = lazy(() =>
  import("../components/app-with-case-management")
);

export default function App() {
  if (typeof window === "undefined") {
    return null;
  }

  return (
    <Suspense fallback={<LayoutWithLoading title="Loading" />}>
      <AppWithCaseManagement />
    </Suspense>
  );
}
